import React, { FunctionComponent } from 'react'

type Props = {
  label: string
  active: boolean
  onClick: () => void
}

export const FilterButton: FunctionComponent<Props> = ({ label, active, onClick }) => {
  const defaultClasses = 'py-2 px-4 border'
  const classes = active ? defaultClasses + ' bg-blue-800 text-white' : defaultClasses

  return (
    <button type="button" className={classes} onClick={onClick}>
      {label}
    </button>
  )
}
