import React, { FunctionComponent } from 'react'

type Props = {
  results: any[]
  total: number
  addItemToCart: (item: any) => void
}

export const SearchResults: FunctionComponent<Props> = ({ results, total, addItemToCart }) => {
  return (
    <ul>
      <li className="text-center p-4 bg-gray-100">Viser 30 av {total} treff</li>
      {results.map((result) => (
        <li key={result.item_id} className="border-b p-4" onClick={() => addItemToCart(result)}>
          {result.article_number_with_alpha} - {result.description}
        </li>
      ))}
    </ul>
  )
}
