import { APP_CONFIG } from '../config'

export function client(endpoint: string, { body, ...customConfig }: any = {}) {
  const token = window.localStorage.getItem(APP_CONFIG.TOKEN)
  const headers: HeadersInit = { 'Content-Type': 'application/json' }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const config: RequestInit = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  return window.fetch(`${APP_CONFIG.API_URL}/${endpoint}`, config).then(async (response) => {
    const data = await response.json()

    if (response.status === 401 || response.status === 403) {
      localStorage.removeItem(APP_CONFIG.TOKEN)
      window.location.reload()
    }

    if (response.ok) {
      return data
    } else {
      return Promise.reject(data)
    }
  })
}
