import React, { FormEvent, useEffect, useState } from 'react'
import { client } from '../lib/api-client'
import { APP_CONFIG } from '../config'
import { AppHeader } from './common/AppHeader'

export const UnAuthenticatedApp = () => {
  const [requestStatus, setRequestStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [wholesalers, setWholesalers] = useState<any[]>()
  const [wholesalerId, setWholesalerId] = useState<string>('')
  const [workshopNumber, setWorkshopNumber] = useState<string>('')
  const [customerNumber, setCustomerNumber] = useState<string>('')
  const [error, setError] = useState<string>('')

  const formNotValid = !Boolean(wholesalers) || !Boolean(workshopNumber) || !Boolean(customerNumber)

  useEffect(() => {
    setError('')

    client(`wholesale`)
      .then((data) => {
        setWholesalers(data)
      })
      .catch(() => setError('Klarte ikke hente grossister'))
  }, [])

  const login = (e: FormEvent) => {
    e.preventDefault()
    setRequestStatus('pending')

    client(`wholesale/customer-auth`, {
      method: 'POST',
      body: {
        workshop_number: workshopNumber,
        customer_number: customerNumber,
        app_id: APP_CONFIG.APP_ID,
      },
    })
      .then(({ token }) => {
        localStorage.setItem(APP_CONFIG.TOKEN, token)
        window.location.replace('/')
      })
      .catch((error) => {
        setRequestStatus('failure')
        setError(error.message)
      })
  }

  const isLoggingIn = requestStatus === 'pending'

  return (
    <div className="max-w-6xl mx-auto">
      <AppHeader />

      <form className="mx-auto max-w-sm" onSubmit={login}>
        <h2 className="text-3xl text-center mb-4">Logg inn</h2>

        {error && <div className="text-red-700">{error}</div>}

        <div className="mt-4">
          <label htmlFor="wholesaler" className="block mb-1">
            Grossist
          </label>
          <select
            id="wholesaler"
            className="border p-2 w-full"
            value={wholesalerId}
            onChange={({ target: { value } }) => setWholesalerId(value)}
          >
            <option>- Velg -</option>
            {wholesalers
              ? wholesalers.map((wholesaler, i) => (
                  <option key={`w${i}`} value={wholesaler.wholesaler_id}>
                    {wholesaler.name}
                  </option>
                ))
              : null}
          </select>
        </div>

        <div className="mt-4">
          <label htmlFor="workshopNumber" className="block mb-1">
            Verkstednummer
          </label>
          <input
            id="workshopNumber"
            type="text"
            className="border p-2 w-full"
            value={workshopNumber}
            onChange={({ target: { value } }) => {
              setWorkshopNumber(value)
            }}
          />
        </div>

        <div className="mt-4">
          <label htmlFor="customerNumber" className="block mb-1">
            Kundenummer
          </label>
          <input
            id="customerNumber"
            type="text"
            className="border p-2 w-full"
            value={customerNumber}
            onChange={({ target: { value } }) => {
              setCustomerNumber(value)
            }}
          />
        </div>

        <button
          type="submit"
          className="bg-blue-900 text-white w-full py-3 rounded disabled:opacity-50 mt-4"
          disabled={formNotValid || isLoggingIn}
        >
          {isLoggingIn ? 'Logger inn...' : 'Logg inn'}
        </button>
      </form>

      <footer className="text-center text-gray-500 mt-6">
        Cars Software {new Date().getFullYear()}
      </footer>
    </div>
  )
}
