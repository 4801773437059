import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { client } from '../../../lib/api-client'
import { Cart } from './Cart'
import { SearchField } from './SearchField'
import { SearchType } from '../../../types'
import { SearchResults } from './SearchResults'
import { APP_CONFIG } from '../../../config'
import { OrderConfirmation } from './Confirmation'

type Props = {
  workshop: any
}

const fetchReplacementProduct = (wholesalerId: string, searchTerm: string) => {
  return client(
    `ad-item/${wholesalerId}/search?term=${searchTerm}&limit=30&offset=0&field=articleNumber`
  ).then(({ total, results }) => {
    if (total > 0) {
      return results[0]
    }

    throw new Error('Ingen erstatningsvare funnet')
  })
}

export const SearchPage: FunctionComponent<Props> = ({ workshop }) => {
  const { wholesaler_id: wholesalerId } = workshop
  const [reqStatus, setReqStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchType, setSearchType] = useState<SearchType>('ARTICLE_NUMBER')
  const [resultTotal, setResultTotal] = useState<number>(0)
  const [results, setResults] = useState<any[]>()
  const [cartItems, setCartItems] = useState<any[]>([])
  const [requisitionNumber, setRequisitionNumber] = useState<string>('')
  const [text, setText] = useState<string>('')
  const [sendOrderStatus, setSendOrderStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [orderSent, setOrderSent] = useState<boolean>(false)

  const incrementCartItem = useCallback(
    (item: any) =>
      cartItems.map((ei) => (ei.item_id === item.item_id ? { ...ei, amount: ei.amount + 1 } : ei)),
    [cartItems]
  )

  const decrementCartItem = (item: any) =>
    cartItems.map((ei) =>
      ei.item_id === item.item_id ? { ...ei, amount: ei.amount > 1 ? ei.amount - 1 : 1 } : ei
    )

  const addItemToCart = useCallback(
    (item: any, amount = 1) => {
      const existsInCart = cartItems.some((ei) => ei.item_id === item.item_id)

      if (existsInCart) {
        setCartItems(incrementCartItem(item))
      } else {
        setCartItems([...cartItems, { ...item, amount }])
      }

      setResultTotal(0)
      setResults([])
      setSearchTerm('')
    },
    [cartItems, incrementCartItem]
  )

  const onAddItemToCart = useCallback(
    (item: any, amount = 1) => {
      if (item.replacement_product) {
        fetchReplacementProduct(wholesalerId, item.replacement_product)
          .then((item) => addItemToCart(item))
          .catch((error) => console.log(error))
      }

      addItemToCart(item, amount)
    },
    [addItemToCart, wholesalerId]
  )

  const addItemsFromFile = async (items: { barcode: string; quantity: number }[]) => {
    setReqStatus('pending')

    await Promise.all(
      items.map(({ barcode, quantity }) =>
        client(`ad-item/${wholesalerId}/scan/${barcode}`).then((item) => ({
          item,
          amount: quantity,
        }))
      )
    )
      .then((results) => {
        let newState: any = [...cartItems]

        results.forEach((r) => {
          const exists = cartItems.some((ci) => ci.item_id === r.item.item_id)

          if (exists) {
            newState = newState.map((ei: any) =>
              ei.item_id === r.item.item_id ? { ...ei, amount: ei.amount + r.amount } : ei
            )
          } else {
            newState = [...newState, { ...r.item, amount: r.amount }]
          }
        })

        setCartItems(newState)
        setReqStatus('idle')
      })
      .catch((error) => {
        console.log(error)
        setReqStatus('failure')
      })
  }

  useEffect(() => {
    if (!searchTerm) {
      return
    }

    const id = setTimeout(() => {
      setReqStatus('pending')

      const url =
        searchType === 'ARTICLE_NUMBER'
          ? `ad-item/${wholesalerId}/search?term=${searchTerm}&limit=30&offset=0&field=articleNumber`
          : searchType === 'BARCODE_SCAN'
          ? `ad-item/${wholesalerId}/scan/${searchTerm}`
          : `ad-item/${wholesalerId}/search?term=${searchTerm}&limit=30&offset=0`

      client(url)
        .then((response) => {
          if (!response.results) {
            onAddItemToCart(response, 1)
          } else if (response.results.length === 1) {
            onAddItemToCart(response.results[0], 1)
          } else {
            setResults(response.results)
            setResultTotal(response.total)
          }

          setReqStatus('idle')
        })
        .catch(() => setReqStatus('failure'))
    }, 500)

    return () => {
      setReqStatus('idle')
      clearTimeout(id)
    }
  }, [searchTerm, searchType, wholesalerId, onAddItemToCart])

  const sendOrder = () => {
    setSendOrderStatus('pending')

    const data = {
      wholesale_id: wholesalerId,
      app_id: APP_CONFIG.APP_ID,
      customer_number: workshop.customer_number.toString(),
      requisition_number: requisitionNumber,
      rows: cartItems.map((cartItem) => ({
        amount: cartItem.amount,
        article_number: cartItem.article_number_with_alpha,
      })),
      text,
    }

    client(`ad-item/${wholesalerId}/send-order`, { body: data })
      .then(() => {
        setSendOrderStatus('idle')
        setOrderSent(true)
      })
      .catch(() => setSendOrderStatus('failure'))
  }

  const resetOrder = () => {
    setOrderSent(false)
    setCartItems([])
  }

  const cartTotal = cartItems
    .map(({ price, amount }) => price * amount)
    .reduce((acc, value) => acc + value, 0)

  if (orderSent) {
    return <OrderConfirmation cartItems={cartItems} cartTotal={cartTotal} confirm={resetOrder} />
  }

  if (sendOrderStatus === 'pending') {
    return <div className="mt-10 text-center text-xl">Sender ordre...</div>
  }

  return (
    <div className="relative">
      <SearchField
        searchTerm={searchTerm}
        onTermChange={setSearchTerm}
        searchType={searchType}
        onSearchTypeChange={setSearchType}
        addItemsFromFile={addItemsFromFile}
      />

      {sendOrderStatus === 'failure' && (
        <div className="text-red-500 mt-4 mb-4">
          Det oppstod dessverre en feil. Vennligst forsøk igjen senere
        </div>
      )}

      {reqStatus === 'pending' && <div className="text-center my-6">Søker...</div>}

      {reqStatus !== 'pending' && results && results.length > 0 && (
        <SearchResults results={results} total={resultTotal} addItemToCart={onAddItemToCart} />
      )}

      {cartItems.length > 0 && (
        <React.Fragment>
          <Cart
            items={cartItems}
            onIncrement={(item) => setCartItems(incrementCartItem(item))}
            onDecrement={(item) => setCartItems(decrementCartItem(item))}
            onRemoveItem={(item) => {
              const newState = cartItems.filter((ei) => ei.item_id !== item.item_id)
              setCartItems(newState)
            }}
            requisitionNumber={requisitionNumber}
            onRequisitionNumberChange={setRequisitionNumber}
            text={text}
            onTextChange={setText}
          />

          <div className="mt-6 text-lg flex justify-end">Total: kr. {cartTotal},-</div>

          <div className="flex justify-end">
            <button
              onClick={sendOrder}
              className="mt-6 border border-blue-800 rounded w-1/4 py-2 px-4 text-white bg-blue-800"
            >
              Bestill
            </button>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
