import React, { FunctionComponent, useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { AppHeader } from './common/AppHeader'
import { SearchPage } from './pages/search/SearchPage'
import { client } from '../lib/api-client'
import jwtDecode from 'jwt-decode'
import { APP_CONFIG } from '../config'

type Props = {
  token: string
}

export const AuthenticatedApp: FunctionComponent<Props> = ({ token }) => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('pending')
  const [workshop, setWorkshop] = useState<any>()

  useEffect(() => {
    setStatus('pending')
    const { id } = jwtDecode(token) as any

    client(`workshop/${id}`)
      .then((workshop) => {
        setWorkshop(workshop)
        setStatus('idle')
      })
      .catch(() => setStatus('failure'))
  }, [token])

  return (
    <BrowserRouter>
      <div className="max-w-6xl mx-auto">
        <AppHeader />

        {status === 'pending' ? (
          <div>Vennligst vent...</div>
        ) : status === 'failure' ? (
          <div className="mt-6 text-center">
            <div className="text-red-500 text-lg">
              Det oppstod dessverre en feil. Vennligst prøv igjen senere.
            </div>
            <button
              className="border bg-blue-800 rounded py-2 px-4 text-white mt-6"
              onClick={() => {
                setStatus('idle')
                window.location.reload()
              }}
            >
              Prøv igjen
            </button>
          </div>
        ) : (
          <React.Fragment>
            <div className="flex items-center justify-between mt-6">
              <div>Grossist: {workshop.wholesaler_name}</div>
              <button
                className="border border-blue-800 rounded py-2 px-4 text-blue-800"
                onClick={() => {
                  localStorage.removeItem(APP_CONFIG.TOKEN)
                  window.location.reload()
                }}
              >
                Logg ut ({workshop.name})
              </button>
            </div>

            <Switch>
              <Route path="/" exact>
                <SearchPage workshop={workshop} />
              </Route>
            </Switch>
          </React.Fragment>
        )}
      </div>
    </BrowserRouter>
  )
}
