import React, { FunctionComponent } from 'react'
import { SearchType } from '../../../types'
import { FilterButton } from '../../common/FilterButton'

type Props = {
  searchType: SearchType
  onSearchTypeChange: (type: SearchType) => void
  searchTerm: string
  onTermChange: (term: string) => void
  addItemsFromFile: (lines: any[]) => void
}

export const SearchField: FunctionComponent<Props> = ({
  searchType,
  onSearchTypeChange,
  searchTerm,
  onTermChange,
  addItemsFromFile,
}) => {
  const label =
    searchType === 'ARTICLE_NUMBER'
      ? 'Søk varenummer'
      : searchType === 'TEXT_SEARCH'
      ? 'Søk beskrivelse'
      : 'Scan strekkoden'

  const onFileChanged = (event: any) => {
    const file = event.target.files[0]
    const reader = new FileReader()

    if (file.size > 5000000) {
      alert('Filen er for stor (over 5 mb)')
    } else {
      reader.onload = () => {
        const text = reader.result as any
        let lines = text.split('\n')

        if (lines && lines.length > 0) {
          lines = lines.map((line: string) => {
            const parts = line.split(',')
            const barcode = parts[0]
            const quantity = parseInt(parts[1], 10)
            return { barcode, quantity }
          })

          lines = lines.filter((line: { barcode: string | any[] }) => line.barcode.length > 1)
          addItemsFromFile(lines)

          event.target.value = ''
        }
      }

      reader.readAsText(file)
    }
  }

  return (
    <React.Fragment>
      <div className="flex mt-8 justify-between">
        <div>
          <FilterButton
            label="Varenummer"
            active={searchType === 'ARTICLE_NUMBER'}
            onClick={() => onSearchTypeChange('ARTICLE_NUMBER')}
          />
          <FilterButton
            label="Beskrivelse"
            active={searchType === 'TEXT_SEARCH'}
            onClick={() => onSearchTypeChange('TEXT_SEARCH')}
          />
          <FilterButton
            label="Strekkoder"
            active={searchType === 'BARCODE_SCAN'}
            onClick={() => onSearchTypeChange('BARCODE_SCAN')}
          />
        </div>

        {searchType === 'BARCODE_SCAN' && (
          <label className="border rounded flex items-center px-4 text-blue-800 border-blue-800">
            <input type="file" className="hidden" onChange={onFileChanged} />
            Hent strekkode fil
          </label>
        )}
      </div>

      <label htmlFor="searchInput" className="block mt-8 bg-gray-100 p-6">
        <input
          type="text"
          placeholder={label}
          value={searchTerm}
          onChange={({ target }) => onTermChange(target.value)}
          autoFocus={true}
          className="border py-3 w-full px-5 text-2xl"
        />
      </label>
    </React.Fragment>
  )
}
