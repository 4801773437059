import React, { FunctionComponent } from 'react'

type Props = {
  cartItems: any[]
  cartTotal: number
  confirm: () => void
}

export const OrderConfirmation: FunctionComponent<Props> = ({ cartItems, cartTotal, confirm }) => {
  return (
    <React.Fragment>
      <div className="flex items-center justify-between mt-6">
        <h1 className="text-3xl">Ordrebekreftelse</h1>

        <div>
          <button type="button" onClick={confirm} className="border rounded py-2 px-4 mr-4">
            Tilbake
          </button>
          <button
            type="button"
            className="border-blue-800 rounded py-2 px-4 text-white bg-blue-800"
            onClick={window.print}
          >
            Print
          </button>
        </div>
      </div>

      <table className="w-full mt-6">
        <thead>
          <tr className="text-left border-b">
            <th className="py-2">Artikkelnummer</th>
            <th>Betegnelse</th>
            <th>Veil. pris</th>
            <th>Antall</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {cartItems.map((ci) => (
            <tr key={`coi-${ci.item_id}`} className="border-b">
              <td className="py-4">{ci.article_number_with_alpha}</td>
              <td className="py-4">{ci.description}</td>
              <td>kr. {ci.price},-</td>
              <td>{ci.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-6 text-lg flex justify-end">Total: kr. {cartTotal},-</div>
    </React.Fragment>
  )
}
