import React from 'react'
import { UnAuthenticatedApp } from './components/UnAuthenticatedApp'
import { AuthenticatedApp } from './components/AuthenticatedApp'
import { APP_CONFIG } from './config'

function App() {
  const token = localStorage.getItem(APP_CONFIG.TOKEN)

  return token ? <AuthenticatedApp token={token} /> : <UnAuthenticatedApp />
}

export default App
