import React, { FunctionComponent } from 'react'

type Props = {
  items: any[]
  onIncrement: (item: any) => void
  onDecrement: (item: any) => void
  onRemoveItem: (item: any) => void
  requisitionNumber: string
  onRequisitionNumberChange: (requisitionNumber: string) => void
  text: string
  onTextChange: (requisitionNumber: string) => void
}

export const Cart: FunctionComponent<Props> = ({
  items,
  onIncrement,
  onDecrement,
  onRemoveItem,
  requisitionNumber,
  onRequisitionNumberChange,
  text,
  onTextChange,
}) => {
  return (
    <div className="mt-6">
      <div className="flex space-x-4">
        <div className="w-1/4">
          <label htmlFor="requisitionNumber" className="font-semibold block mb-2">
            Rekvisisjonsnr.
          </label>
          <input
            id="requisitionNumber"
            type="text"
            value={requisitionNumber}
            onChange={({ target: { value } }) => onRequisitionNumberChange(value)}
            className="border p-2 w-full"
          />
        </div>

        <div className="w-1/4">
          <label htmlFor="note" className="font-semibold block mb-2">
            Tekst
          </label>
          <input
            id="note"
            type="text"
            value={text}
            onChange={({ target: { value } }) => onTextChange(value)}
            className="border p-2 w-full"
          />
        </div>
      </div>

      <table className="w-full mt-6">
        <thead>
          <tr className="text-left border-b">
            <th className="py-2">Artikkelnummer</th>
            <th>Beskrivelse</th>
            <th>Veil. pris</th>
            <th>Antall</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {items.map((ci) => (
            <tr key={`ci-${ci.item_id}`} className="border-b blink">
              <td className="py-4">{ci.article_number_with_alpha}</td>
              <td>{ci.description}</td>
              <td>kr. {ci.price},-</td>
              <td>{ci.amount}</td>
              <td>
                <button
                  type="button"
                  className="border border-blue-800 rounded-l py-2 px-4 text-blue-800"
                  onClick={() => onDecrement(ci)}
                >
                  -
                </button>
                <button
                  type="button"
                  className="border border-blue-800 rounded-r border-l-0 py-2 px-4 text-blue-800"
                  onClick={() => onIncrement(ci)}
                >
                  +
                </button>
              </td>
              <td className="text-right">
                <button
                  type="button"
                  className="border border-red-500 rounded py-2 px-4 text-red-500"
                  onClick={() => onRemoveItem(ci)}
                >
                  Fjern
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
