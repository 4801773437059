import React from 'react'
import bilXtraLogo from '../../images/Bilxtra_verksted_logo_01.png'
import carsLogo from '../../images/cars_logo-3.png'

export const AppHeader = () => {
  return (
    <div className="flex justify-between items-center py-4 hide-on-print">
      <img src={bilXtraLogo} alt="BilXtra verksted logo" style={{ height: '3rem' }} />
      <h1 className="text-6xl font-bold text-blue-900 text-shadow-sm">BestillDeler.no</h1>
      <img src={carsLogo} alt="Cars Software logo" style={{ height: '6rem' }} />
    </div>
  )
}
