export const APP_CONFIG =
  process.env.REACT_APP_ENV === 'production'
    ? {
        APP_ID: 2,
        API_URL: `https://cars-web-api-production.herokuapp.com/api/v3`,
        TOKEN: 'bestilldeler-token',
      }
    : process.env.REACT_APP_ENV === 'dev'
    ? {
        APP_ID: 6,
        API_URL: `http://localhost:5000/api/v3`,
        TOKEN: 'bestilldeler-token',
      }
    : {
        APP_ID: 6,
        API_URL: `https://cars-web-api-staging.herokuapp.com/api/v3`,
        TOKEN: 'bestilldeler-token',
      }
